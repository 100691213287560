<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Publications</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <div
          v-if="loading"
          class="loading position-relative"
        >
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
        <div
          v-else
          class="row"
        >
          <div class="col">
            <div class="row">
              <div
                v-if="canEdit"
                class="col-sm-10 mb-1"
              >
                <a
                  class="btn btn-dark btn-sm me-1"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasChoosePublications"
                  aria-controls="offcanvasChoosePublications"
                  @click="openChoosePublications()"
                ><i data-feather="git-commit" /> Choose publications</a>
                <a
                  class="btn btn-dark btn-sm"
                  @click="importPreselectedPublications()"
                ><i data-feather="git-branch" /> Bring selected publications</a>
              </div>
              <div
                class="text-end"
                :class="canEdit ? 'col-sm-2' : 'col-sm-12'"
              >
                <!-- <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur doloremque sunt adipisci temporibus consectetur fugit, ea error voluptatum placeat voluptatem eveniet accusamus rem. Velit corporis voluptas aspernatur veniam nobis quasi.</p> -->
                <label class="text-danger"><strong>{{ contentPublicationLength }}</strong></label>
              </div>
            </div>

            <div class="mb-1 required">
              <div id="full-container">
                <div
                  class="editor"
                >
                  <p
                    v-for="(publication, index) in publications"
                    :key="index"
                  >
                    - <span class="no-linebreake" v-html="publication.full_reference" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChoosePublications
      v-if="!loading"
      :memoir-profile-year="memoirProfileYear"
      :memoir-profile-id="memoirProfileId"
      :publications-from-memoir="publications"
      :memoir-profile-user="memoirProfileUser"
      @needFetch="needFetch"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChoosePublications from './ChoosePublications.vue'

export default {
  components: {
    ChoosePublications,
  },
  props: {
    memoirProfileId: { type: Number, required: true, default: 0 },
    memoirProfileYear: { type: [Number, String], required: true, default: 0 },
    memoirProfileUser: { type: Object, required: true, default: () => {} },
    canEdit: { type: Boolean, required: true, default: () => {} },
    contentPublicationLength: { type: Number, required: true, default: 0 },
  },
  data() {
    return {
      loading: false,
      pubicationReferenceLength: 0,
    }
  },
  computed: {
    ...mapGetters({
      publications: 'memoirsProfile/publications',
      loggedUser: 'auth/admin',
    }),
    publicationLength() {
      return this.publications.length
    },
  },
  watch: {
    publicationLength() {
      this.calculateLength()
    },
  },
  mounted() {
    this.$store.dispatch('modals/toggleChoosePublications', false)
    this.reloadData()
  },
  methods: {
    openChoosePublications() {
      this.$store.dispatch('modals/toggleChoosePublications', true)
    },
    async importPreselectedPublications() {
      this.loading = true
      const resp = await this.$store.dispatch('memoirsProfile/setPublications', { id: this.memoirProfileId, type: 'preselected' })
      if (resp.data && resp.data.publications <= 0) {
        this.$toast.warning('No publications found')
      }
      this.reloadData()
    },
    async reloadData() {
      if (this.memoirProfileId !== 0) {
        this.loading = true
        await this.$store.dispatch('memoirsProfile/getPublications', this.memoirProfileId)
        this.calculateLength()
        this.loading = false
      }
    },
    needFetch(need) {
      if (need) {
        this.reloadData()
      }
    },
    calculateLength() {
      this.pubicationReferenceLength = 0
      this.publications.forEach(item => {
        this.pubicationReferenceLength += item.filtered_full_reference.length
      })
      this.$emit('publicationReferenceLength', this.pubicationReferenceLength)
    },
  },
}
</script>

<style>

</style>
