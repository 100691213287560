<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">People in your research team</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <p>(This information is not part of your personal page in the Memoir. It will only appear as aggregate in the KEY FIGURES section.)</p>
        <p>This section is merely informative. It shows the number of people you have been able to bring to and/or maintain in the Catalan Research System (each unit corresponds to a 1 person funded for a full year).</p>
        <p>Below are the numbers corresponding to the people you have already entered in the ICREA website, remember that they are linked to grants, and therefore they are in the “Grants & Support” section. Please check the numbers that show in this page and make sure that they are correct. You can go directly to the source of these data by clicking on the button “Go to Grants & Support”. </p>
        <p>The distribution of people follows this scheme:</p>
        <ul>
          <li>OWN Funding: People paid directly by your grants or contracted research (e.g., MECD, ERC, Marató, etc.) or paid by your Host Institution working in your research groups.</li>
          <li>EXTERNAL Funding: People financed by external calls (supported/endoresed by you) e.g., FI, FPI, FPU, Marie Curie, Beatriu de Pinós, Juan de la Cierva, etc. In general, all personnel contracts not signed by your Host Institution.</li>
        </ul>
        <p>
          <a
            :href="$router.resolve({ name: 'researcher.grants.index', query: { prefilterUser: item.user.id, actAs: $route.query.actAs } }).href"
            target="_blank"
            class="btn btn-primary btn-sm"
            title="Go to Grants & Support section"
          >Go to Grants & Support</a></p>
        <div
          v-if="loading"
          class="loading position-relative"
        >
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
        <div v-else>
          <div
            class="row"
          >
            <div class="col-sm-4">
              <div class="card shadow-none bg-transparent border-secondary">
                <div class="card-body">
                  <h6>People in your research group during {{ item.year }}</h6>
                  <h4>Total number of fulltime people: {{ grants.total }}</h4>
                  <p>(1 = 1 person, 1 year, 100% dedication)</p>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div
            v-for="grant, index in grants.data"
            :key="index"
          >
            <h5>
              {{ index }}
            </h5>
            <div
              class="row"
            >
              <div
                v-for="number, name in grant"
                :key="name"
                style="flex: 0 0 auto;width: 13.66667%;"
              >
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >{{ name }}</label>
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    :value="number"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      item: 'memoirsProfile/item',
      grants: 'memoirsProfile/grants',
    }),
  },
  async mounted() {
    await this.$store.dispatch('memoirsProfile/getGrants', this.item.id)
    this.loading = false
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
}
</script>
